<template>
  <div>
    <!--
    <div class="divider">
      <div class="ant-steps-item-container"></div>
      <div class="ant-steps-item-icon">
        <span class="ant-steps-icon">1</span>
      </div>
      <div class="ant-steps-item-content">
        <div class="ant-steps-item-title">Данные для личного кабинета</div>
      </div>
      <br />
      <br />
    </div>
    <div class="content-container">
      <ClientInfoForUser ref="clientInfoForUser" />
    </div>
    -->
    <div class="divider">
      <div class="ant-steps-item-container"></div>
      <div class="ant-steps-item-icon">
        <span class="ant-steps-icon">1</span>
      </div>
      <div class="ant-steps-item-content">
        <div class="ant-steps-item-title">Данные дилера</div>
      </div>
      <br />
      <br />
    </div>
    <div class="content-container">
      <ClientData ref="clientDataComponent" />
    </div>

    <template v-if="clientTypeId === '1'">
      <div class="divider">
        <div class="ant-steps-item-container"></div>
        <div class="ant-steps-item-icon">
          <span class="ant-steps-icon">3</span>
        </div>
        <div class="ant-steps-item-content">
          <div class="ant-steps-item-title">Контактная информация</div>
        </div>
        <br />
        <br />
      </div>
      <div class="content-container">
        <ContactInfo />
      </div>
    </template>

    <ButtonsHolder :callBack="handleAcrtionClientData" />
  </div>
</template>

<script>
import ClientData from "@/components/clients/ClientData"
import ContactInfo from "@/components/clients/ContactInfo"
//import ClientInfoForUser from "@/components/clients/ClientInfoForUser"
import ButtonsHolder from "@/components/clients/ButtonsHolder"
import { mapMutations, mapActions, mapGetters } from "vuex"
//import { API_PREFIX } from "@/services/api/v1/const"
export default {
  name: "CreateNewClient",
  mounted() {
    this.setCreateForm({
      code: "",
      name: "",
      phone: "",
      // TODO: Спроси у Паши
      paymentGroup: {
        id: "",
        name: "",
        ordering: "",
      },
      headName: "",
      headPosition: "",
      deliveryPeriod: 0,
      legalInfo: {
        name: "",
        paymentAccount: "",
        bankId: "",
        bankName: "",
        correspondedAccount: "",
        underlyingDocument: "",
        inn: "",
        kpp: "",
        ogrn: "",
        okpo: "",
        address: "",
      },
      legalInfoPhysicalAddress: [""],
      contacts: [
        {
          type: "Взаиморасчеты",
          name: "",
          phone: "",
          email: "",
        },
        {
          type: "Отправка прайс-листов",
          name: "",
          phone: "",
          email: "",
        },
        {
          type: "Руководитель",
          name: "",
          phone: "",
          email: "",
        },
        {
          type: "Заказы",
          name: "",
          phone: "",
          email: "",
        },
        {
          type: "Возвраты",
          name: "",
          phone: "",
          email: "",
        },
        /*{
          type: "Обновление паролей",
          name: "",
          phone: "",
          email: "",
        },*/
      ],
    })
  },
  components: {
    ClientData,
    ContactInfo,
    ButtonsHolder,
    // ClientInfoForUser,
  },
  computed: {
    ...mapGetters({
      clientTypeId: "clients/clientTypeId",
    }),
  },
  methods: {
    ...mapActions({
      fetchClients: "clients/fetchClients",
      createUser: "auth/createUser",
      createClient: "clients/createClient",
      bindClientWithUser: "clients/bindClientWithUser",
    }),
    checkClient() {
      this.$refs.clientDataComponent.$v.fields.$touch()
      // this.$refs.clientInfoForUser.$v.fields.$touch()
    },
    handleChange(value) {
      console.log(`selected ${value}`)
    },
    ...mapMutations({
      setCreateForm: "clients/SET_CREATE_FORM",
    }),
    async handleAcrtionClientData() {
      await this.addNewClient()
    },
    async addNewClient() {
      // const [login, password, email, amount] =
      //   this.$refs.clientInfoForUser.$v.fields.$model

      let postData = {}
      /*
      this.$refs.clientDataComponent.$v.fields.$model.forEach(p => {
        if (!["legalInfoPhysicalAddress"].includes(p.createclientname)) {
          postData[p.createclientname] = p.value
        }
        // let field = p.createclientname
        // const subfield = field.replace("legalInfo", "")
        // if (subfield !== field) {
        //   field = field.replace(subfield, "")
        //   if (postData[field] === undefined) {
        //     postData[field] = {}
        //   }
        //   postData[field][subfield] = p.value
        // } else {
        //   postData[field] = p.value
        // }
      })
      console.log(postData)
       */

      //
      // switch (this.clientTypeId) {
      //   case "1":
      //     postData = {
      //       user: {
      //         name: login.value,
      //         email: email.value,
      //         password: password.value,
      //       },
      //       financeAccount: {
      //         amount: amount.value,
      //       },
      //     }
      //     break
      //   case "2":
      //     postData = {
      //       user: {
      //         name: login.value,
      //         email: email.value,
      //         password: password.value,
      //       },
      //     }
      //     break
      // }

      await this.createClient(postData)
      await this.$router.push(`/clients`)
    },
  },
}
</script>

<style scoped>
.ant-advanced-search-form {
  padding: 24px;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  margin-bottom: 10px;
}

.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

#advanced-search .ant-form {
  max-width: none;
}
#advanced-search .search-result-list {
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  padding: 20px 40px;
}
.search-result-text {
  margin: 20px 0 10px 0;
}
.tabsinfo {
  min-height: 570px;
}
.buttonsholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 auto;
}
.selects {
  width: 200px;
}
.optGroupSelect {
  margin-top: 10px;
}
.newClientHeader {
  font-weight: bold;
}
.ant-collapse-header {
  font-size: 16px !important;
  font-weight: 700 !important;
}
</style>
<style lang="scss" scoped>
.divider {
  margin-bottom: -30px;
  &:not(:first-child) {
    margin-top: 30px;
  }
}
</style>
