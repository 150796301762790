<template>
  <div v-if="data">
    <a-table
      bordered
      :columns="columns"
      :data-source="data"
      :rowKey="row => row.type"
      :pagination="{ defaultPageSize: 10, hideOnSinglePage: true }"
    >
      <span slot="name" slot-scope="text, record, index">
        <a-input
          placeholder="Введите ваше ФИО"
          @input="e => onContactFieldChange(e, index, 'name')"
          :value="data[index].name"
        />
      </span>
      <span slot="phone" slot-scope="text, record, index">
        <a-input
          placeholder="Введите ваш номер телефона"
          @input="e => onContactFieldChange(e, index, 'phone')"
          :value="data[index].phone"
        />
      </span>
      <span slot="email" slot-scope="text, record, index">
        <a-input
          placeholder="Введите ваш адрес эл. почты"
          @input="e => onContactFieldChange(e, index, 'email')"
          :value="data[index].email"
        />
      </span>
    </a-table>
    <div
      v-if="this.$route.name !== 'CreateNewClient' && !isOwner"
      class="buttonsholder"
    >
      <ButtonsHolderUpdateClient />
    </div>
  </div>
</template>

<script>
const columns = [
  {
    title: "Тип контакта",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "ФИО",
    key: "name",
    dataIndex: "name",
    scopedSlots: { customRender: "name" },
  },
  {
    title: "Телефон",
    dataIndex: "phone",
    key: "phone",
    scopedSlots: { customRender: "phone" },
  },
  {
    title: "Электронная почта",
    dataIndex: "email",
    key: "email",
    scopedSlots: { customRender: "email" },
  },
]

import ButtonsHolderUpdateClient from "@/components/clients/ButtonsHolderUpdateClient"
import { mapMutations, mapGetters } from "vuex"

export default {
  name: "ContactInfo",
  components: {
    ButtonsHolderUpdateClient,
  },
  computed: mapGetters({
    createForm: "clients/createForm",
    data: "clients/data",
    isOwner: "auth/isOwner",
  }),
  data() {
    return {
      columns,
    }
  },
  methods: {
    ...mapMutations({
      setContactField: "clients/SET_CREATE_FORM_CONTACT_FIELD",
    }),
    onContactFieldChange(e, index, field) {
      this.setContactField({
        index,
        field,
        value: e.target.value,
      })
    },
  },
}
</script>

<style scoped>
.buttonsholder {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 0 auto;
}
</style>
